<template>
  <div>
    <div class="p-3">
      <div class="d-flex justify-content-between">
        <p style="font-size: 16px">
          ตัดยอดเงินประกัน
          <span v-if="side === 'deposit'">[ เงินเข้า ]</span>
          <span v-if="side === 'withdraw'">[ เงินออก ]</span>
        </p>
        <p style="font-size: 16px">{{ query_date }}</p>
      </div>

      <!-- <p style="font-size: 18px">
        เงินเข้า ภายใน (ไม่นับโอนล่วงหน้า ไม่นับเช่าต่อ เฉพาะรายการรับวันนี้
        ยอดต้องโอนไป OD วันนี้)
      </p> -->
      <div
        v-for="(payment, index) in payments"
        :key="index"
        v-if="payment.bookbank_info.code_999 != true"
      >
        <p>{{ payment.bookbank_info.display_name }}</p>
        <table class="my-table mb-2">
          <thead>
            <tr>
              <td>วันที่ทำรายการ</td>
              <td>ชื่อ</td>
              <td>วันที่รับ</td>
              <td>วันที่คืน</td>
              <td>Note</td>
              <td>เงินเข้า</td>
              <td>เงินออก</td>
            </tr>
          </thead>
          <tbody style="font-size: 10px">
            <tr v-for="(x, index) in payment.table_data" :key="index">
              <td>{{ x.createdAt | day }} {{ x.createdAt | time }}</td>
              <td>
                {{ x.member.first_name }} {{ x.member.last_name }} (
                {{ x.member.retro_username || x.member.gen_username }} )
              </td>
              <td>{{ x.start_date | day }} - {{ x.start_date | time }}</td>
              <td>{{ x.end_date | day }} - {{ x.end_date | time }}</td>
              <td>{{ x.note }}</td>

              <td>
                <span v-if="x.side === 'deposit'" class="mb-0">
                  {{ x.deposit_amount | number }}
                </span>
              </td>
              <td>
                <span v-if="x.side === 'withdraw'" class="mb-0">
                  {{ x.withdraw_amount | number }}
                </span>
              </td>
            </tr>
            <tr class="total-row">
              <td colspan="5"></td>
              <td>
                <span v-if="payment.sum_deposit > 0">{{
                  payment.sum_deposit | number
                }}</span>
              </td>
              <td>
                <span v-if="payment.sum_withdraw > 0">{{
                  payment.sum_withdraw | number
                }}</span>
              </td>
            </tr>
            <tr class="total-row">
              <td colspan="5"></td>
              <td colspan="2" class="text-right sum-cell">
                {{ (payment.sum_deposit - payment.sum_withdraw) | number }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      date: null,
      query_date: null,
      payments: [],
      side: null,
    };
  },
  created() {
    const dateParam = this.$route.query.date;
    this.query_date = this.$route.query.date;
    this.side = this.$route.query.side;

    if (dateParam) {
      const [day, month, year] = dateParam.split("-");
      this.date = new Date(year, month - 1, day); // Note: month is 0-indexed
    }
    if (!this.date || isNaN(this.date.getTime())) {
      console.warn("Invalid date format");
    }
    this.load_data();
  },
  computed: {},
  methods: {
    async load_data() {
      await this.$http({
        method: "POST",
        url: `account/print/deposit-payment`,
        data: {
          date: this.query_date,
          side: this.side,
        },
      })
        .then((x) => {
          this.payments = x.data.data;
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
  },
  filters: {
    day: function (date) {
      return moment(date).format("DD/MM");
    },
    time: function (value) {
      if (!value) return "";
      const date = new Date(value);
      // Format the date to Bangkok time in HH:mm format
      return new Intl.DateTimeFormat("en-GB", {
        timeZone: "Asia/Bangkok",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }).format(date);
      //   return moment(date).format("HH:mm");
    },
    number(x) {
      if (x != null) {
        x = parseFloat(x);
        if (Number.isInteger(x)) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      } else {
        return x;
      }
    },
  },
};
</script>

<style>
@page {
  size: A4;
}
.printable-page {
  background-color: white !important;
  padding: 1cm;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Sarabun", sans-serif !important;
  color: black !important;
  background-color: white !important;
}

.my-table {
  width: 100%;
  border-collapse: collapse;
}

.my-table th,
.my-table td {
  padding: 5px 5px 5px 5px; /* shorthand for padding */
  border: 1px solid rgb(198, 198, 198); /* applies border to all sides */
  vertical-align: top;
}

.my-table th:first-child,
.my-table td:first-child {
  border-left: 1px solid rgb(198, 198, 198);
}

.my-table th:last-child,
.my-table td:last-child {
  border-right: 1px solid rgb(198, 198, 198);
}

.sum-cell {
  font-weight: bold; /* Bold text */
  border-top: 1px solid #000; /* Add top border if needed */
  text-align: right; /* Right-align for numeric values */
  font-size: 13px;
}
</style>
